import React from 'react';
import {
  Typography,
  Button,
  Container,
  Switch,
  FormControlLabel,
  Paper,
  Box,
} from '@mui/material';

function WelcomePage({
  handleStart,
  handleWordByWord,
  wordByWord,
  markHallucinations,
  handleMarkHallucinations,
  markLowConfidence,
  handleMarkLowConfidence,
  isEnableToStart,
}) {
  return (
    <Container component="main" maxWidth="sm" sx={{ mt: 4 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: '10px' }}>
        <Box textAlign="center">
          <Typography variant="h3" component="h1" gutterBottom>
          Trust in AI: Visual Descriptions and Human Judgment
          </Typography>
          <Typography variant="body1" paragraph>
            Welcome to the Trust in Language Models Experiment. In this study, you will be presented with descriptions of images generated by a language model. For each description, you will see two statements about the image. Your task is to determine whether each statement is true or false based on the provided description and how much you trust the language model. Additionally, you will be asked to bet points on your level of trust in the model’s description. There will be ten different descriptions for you to evaluate. Thank you for participating!
          </Typography>
          <Box my={2}>
            <FormControlLabel
              control={
                <Switch
                  checked={wordByWord}
                  onChange={(event) => handleWordByWord(event.target.checked)}
                  name="wordByWordSwitch"
                  color="primary"
                />
              }
              label="Display Description Word by Word"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={markHallucinations}
                  onChange={(event) => handleMarkHallucinations(event.target.checked)}
                  name="hallucinationsMarkedSwitch"
                  color="primary"
                />
              }
              label="Display Hallucinations Marked"
            />
            <FormControlLabel
              control={
                <Switch
                  checked={markLowConfidence}
                  onChange={(event) => handleMarkLowConfidence(event.target.checked)}
                  name="lowConfidenceMarkedSwitch"
                  color="primary"
                />
              }
              label="Display Low Confidence Marked"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleStart}
            disabled={!isEnableToStart}
            sx={{ mt: 2 }}
          >
            Start
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default WelcomePage;
